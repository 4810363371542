import moment from "moment";
import { payInfo, sapPay, selectByCode } from "@/views/serviceFeeModule/serviceDetail/api";
import countDown from './../components/countown'
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { queryYue } from "@/views/serviceFeeModule/payOrderPage/api";
export default {
  name: "",
  components: {
    countDown
  },
  data() {
    return {
      pageLoadFlag: false,
      showDisplay: false,
      checkId: '', // 支付方式默认为空
      sapMoney: 0, // 余额
      amount: 0, // 待支付服务费
      detail: {}, // 详细信息
      type:'15691143853',
      time:'',
      d:'',
      h: '',
      m: '',
      s: '',
      sum_h: '',
      agreeBtn:false,
      isLoading:false,
      invoiceType:[]
    };
  },
  computed: {


  },
  mounted() {
    this.getInfo()
    this.getInvoiceType()


  },
  methods: {
    //查看协议
    gotoXY(){
      this.$router.push({
        path: "/serviceFeeModule/logisicsAgreent",
      });
    },
    gotoDetail(item,type){
      // 单个
      if(this.$route.query.batch == 0) {
        if(type == 'serviceNum'){
          this.$router.push({
            path: "/serviceFeeModule/payDetail",
            query: {
              id:item,
              batch:0,
              type:'list',
            }
          });
          return;
        } else {
          if(this.detail.orderCodeList.includes('BHO')) {
            this.$router.push({
              path: "/order/detail",
              query: { id: this.detail.orderId }
            });
            return
          }
          let url = ''
          if(this.detail.orderCodeList.indexOf('P') > -1) {
            url = '/channelOrder/detail' // 渠道订单
          }else if(this.detail.orderCodeList.indexOf('S') > -1) {
            if(this.detail.documentType == 'retail') { // 零售订单
              url = '/retailOrder/detailRetail'
            }else if(this.detail.orderCodeList == 'normal') { // 分销采购订单
              url = 'retailOrder/detailFX'
            }
          }
          this.$router.push({
            path: url,
            query: { id: this.detail.orderId}
          });
          return
        }
      }


    },
    // 获取发票类型
    getInvoiceType() {
      let data = {
        pid: 14963960652
      };
      focusonremarkplz(data).then(res => {
        this.invoiceType = res.data.list;
      });
    },
  //  获取余额
    getYe(){
      let data={
        feeCodeList:this.$route.query.batch == 0?[this.$route.query.id]:this.$route.query.id.split(','),
      }
      queryYue(data).then(res=>{
        this.sapMoney = res.data.msg.canUseMoney
      })
    },
  // 获取支付信息
    getInfo() {
      this.pageLoadFlag = true
      let data =  {
        code:this.$route.query.id,
        batch:this.$route.query.batch==0?false:true
      }
      selectByCode(data).then(res=>{
        // this.sapMoney = res.data.data.sapSurplus
        this.amount = res.data.data.amount
        this.detail = res.data.data;
        this.getYe()
        let timeDUO = this.detail.expireDate - Date.now()
        this.time = moment(timeDUO).format('HH:mm:ss')
        this.countTime()
        this.pageLoadFlag = false

      }).catch(error=>{
        this.pageLoadFlag = false
      })
    },
    // 倒计时
    countTime: function () {
      // 获取当前时间
      let  date = new Date();
      let  now = date.getTime();
      //设置截止时间
      // var endDate = new Date("2020-1-22 23:23:23");
      var end = this.detail.expireDate;

      //时间差
      var leftTime = end - now;
      //定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        this.d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        this.h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        this.m = Math.floor(leftTime / 1000 / 60 % 60) < 10?'0'+ Math.floor(leftTime / 1000 / 60 % 60):Math.floor(leftTime / 1000 / 60 % 60);
        this.s = Math.floor(leftTime / 1000 % 60)< 10?'0'+ Math.floor(leftTime / 1000 % 60):Math.floor(leftTime / 1000 % 60);
        this.sum_h = this.d * 24 + this.h
      }
      //递归每秒调用countTime方法，显示动态时间效果
      // setTimeout(this.countTime, 1000);
    },
    changtype(){

    },

    agree(e){
      this.agreeBtn = e.target.checked
      this.$forceUpdate()
    },
    changePay(num){
      this.checkId = num
    },
    goPay(){
      if(!this.agreeBtn){
        this.$message.warning('请阅读并同意 《物流服务协议》')
        return
      }
      if(!this.type){
        this.$message.warning('请选择发票类型')
        return
      }
      if(!this.checkId ){
        this.$message.warning('请选择支付方式')
        return
      }

      if(this.checkId == 1){
        this.isLoading = true
        let  data = {
          feeCodeList:this.$route.query.batch == 0?[this.$route.query.id]:this.$route.query.id.split(','),
          invoiceType:this.type
        }
        sapPay(data).then(res=>{
          if(res.data.code == 0){
            this.$message.success('付款成功')
            // this.$router.go(-1)
            if(this.$route.query.type == 'list'){
              this.$router.push({
                path: "/serviceFeeModule/servicePaySuccess",
                type:'list',
              });
              this.isLoading = false
              return
            }else{
              this.$router.push({
                path: "/confirm/confirmsuccess",
                query:{
                  type:'list',
                  b2bOrderCode:this.$route.query.b2bOrderCode,
                  orgName:this.$route.query.orgName,
                  channel: this.$route.query.channel?this.$route.query.channel:''
                }
              });
              this.isLoading = false
              return
            }
          }else {
            if(res.data.code == 1){
              this.$message.warning(res.data.msg)
              this.isLoading = false
              return;
            }
            this.$message.success(res.data.msg)
            this.isLoading = false
          }
        }).catch(error=>{
          this.isLoading = false
        })
      } else {
        this.isLoading = true
        let  data = {
          feeCodeList:this.$route.query.batch == 0?[this.$route.query.id]:this.$route.query.id.split(','),
          invoiceType:this.type,
          statusName:this.$route.query.type=='list'?'list':''
        }
        payInfo(data).then(res=>{
          if(res.data.code == 0){
            // 如果接口返回参数payType并且为unionPay 直接打开该页面去电子收银台页面
            if(res.data.data.payType && res.data.data.payType=='unionPay' ) {
              window.location.href=res.data.data.weCharUrl;
              this.isLoading = false
              return
            }
            this.isLoading = false
          }else {
            if(res.data.code == 1){
              this.$message.warning(res.data.msg)
              this.isLoading = false
              return;
            }
            this.$message.warning(res.data.data.errMsg)
            this.isLoading = false
          }
        }).catch(error=>{
          this.isLoading = false
        })
      }
    },
    goBack(){
      this.$router.go(-1)
    },

  }
};