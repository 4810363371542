import service from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
//获取订单详情
export function getOrderDetail(id) {
  return service({
    method: "get",
    url: "/order/orderLine.htm",
    params: {
      id: id
    }
  });
}

export function getImg(data) {
  return dmshttp({
    url: 'toOmsView',
    data
  })
}
// 获取余额
export function queryYue(data) {
  return service({
    method: "post",
    url: "/orderServiceFee/queryYue.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data:data
  });
}

